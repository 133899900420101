import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import { Container, Row, Col } from 'react-grid-system';
import Swiper from 'react-id-swiper';
import commaNumber from 'comma-number';

import styles from './index.module.scss';
import TradeList from './TradeList';
import Settings from './Settings';
import Register from './Register';
import cardNumber from 'utility/cardNumber';

const cx = classNames.bind(styles);

class CardDetail extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.cardOwnerList;

    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      const ownerList = data.result;
      
      if (prevState.ownerList.length !== ownerList.length) {
        return {
          ownerList,
          currentCard: ownerList[0],
        };
      }
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      ownerList: [],
      currentCard: null,
    };
  }

  render() {
    const { ownerList, currentCard } = this.state;

    const params = {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        dynamicBullets: true,
      },
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      },  
      on: {
        slideChangeTransitionEnd: () => {
          if (this.refs.swiper) {
            const index = this.refs.swiper.swiper.realIndex;

            this.setState({
              currentCard: ownerList[index],
            });
          }
        },
      },
    };

    return (
      <Container className={cx('listWrapper')}>
        <Row>
          <Col xs={12} lg={5.8} className={cx('column')}>
            {/* 카드 보유내역 */}
            <div className={cx('title')}>카드 보유내역</div>
            <div className={cx('cardSwiper')}>
              {ownerList.length === 0 && (
                <div className={cx('none')}>카드 보유내역이 없습니다.</div>
              )}
              {ownerList.length > 0 && (
                <Swiper {...params} ref="swiper">
                  {ownerList.map((card, i) => {
                    const cardImageSource = card.cardNo.startsWith('10') ? require('assets/images/club/card-artisienne.png') : require('assets/images/club/card-default.png');

                    return (
                      <div key={i} className={cx('slide')}>
                        <img src={cardImageSource} alt={card.cardNo} className={cx('cardImage')} />
                        <div className={cx('nickNm')}>{card.nickNm}</div>
                        <div className={cx('cardNo')}>{cardNumber(card.cardNo)}</div>
                        <div className={cx('cardPoint')}>{commaNumber(card.restPayPoint)}P</div>
                      </div>
                    );
                  })}
                </Swiper>
              )}
            </div>

            {/* 카드관리 */}
            {currentCard !== null && (
              <Settings card={currentCard} />
            )}

            {/* 카드등록 */}
            <Register />
          </Col>
          <Col xs={12} lg={5.8} offset={{ lg: .2 }} className={cx('column')}>
            {currentCard !== null && (
              <TradeList cardNo={currentCard.cardNo} />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { cardOwnerList } = state;

  return {
    cardOwnerList,
  };
}

export default connect(mapStateToProps)(CardDetail);