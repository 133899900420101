import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import moment from 'moment';
import 'moment/locale/ko.js';
import Ionicon from 'react-ionicons';

import styles from './HoldList.module.scss';
import { couponHoldListActions } from '../../../../../actions';

const cx = classNames.bind(styles);

class HoldList extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.holdList;
    const { page, holdList, isLoadingFirst } = prevState;

    if (isLoadingFirst) {
      return {
        isLoadingFirst: false,
      };
    }

    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      const { holdList: loadedHoldList, totalCount } = data.result;
      const newHoldList = holdList.concat(loadedHoldList);

      return {
        page: page + 1,
        holdList: newHoldList,
        hasMore: newHoldList.length < totalCount,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      holdList: [],
      hasMore: false,
      isLoadingFirst: true,
    };
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    if (token !== null) {
      this.props.actions.couponHoldListActions.request({ token, page: 1 });
    }
  }

  componentWillUnmount() {
    this.props.actions.couponHoldListActions.clear();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.holdList;
    return !isProcessing && !failure && 'message' in data && data.message === 'SUCCESS';
  }

  render() {
    const { holdList, hasMore } = this.state;

    return (
      <div className={cx('list')}>
        {holdList.length === 0 && (
          <div className={cx('none')}>쿠폰 보유내역이 없습니다.</div>
        )}
        {holdList.map((item, i) => {
          const { couponName, validStartDate, validEndDate, /* benefitTypeCode, couponNo */ } = item;
          const fromDate = moment(validStartDate, 'YYYYMMDDHHmmss').format('YYYY.MM.DD');
          const toDate = moment(validEndDate, 'YYYYMMDDHHmmss').format('YYYY.MM.DD');

          return (
            <div key={i} className={cx('item')}>
              <div className={cx('left')}>
                <div className={cx('name')}>{couponName}</div>
                <div className={cx('date')}>{fromDate} ~ {toDate}</div>
              </div>
              <div className={cx('right')}>
                <div className={cx('dday')}>D{moment().diff(moment(validEndDate, 'YYYYMMDDHHmmss'), 'days')}</div>
              </div>
            </div>
          );
        })}
        {hasMore && (
          <div className={cx('moreButton')} onClick={this.loadMore}>
            <div className={cx('loadMoreText')}>더 보기</div>
            <Ionicon icon="ios-arrow-down" fontSize="17px" />
          </div>
        )}
      </div>
    );
  }

  loadMore = () => {
    if (!this.props.holdList.isProcessing) {
      const token = sessionStorage.getItem('access_token');

      if (token !== null) {
        this.props.actions.couponHoldListActions.request({ token, page: this.state.page + 1 });
      }
    }
  }
}

const mapStateToProps = (state) => {
  const { couponHoldList: holdList } = state;

  return {
    holdList,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      couponHoldListActions: bindActionCreators(couponHoldListActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HoldList);