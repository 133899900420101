import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';

import styles from './Register.module.scss';
import { registCard } from 'api';
import { cardOwnerListActions } from '../../../../../actions';

const cx = classNames.bind(styles);

class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cardNo: '',
      cardPinNo: '',
      cardNickName: '',
    };
  }

  render() {
    const { cardNo, cardPinNo, cardNickName } = this.state;

    return (
      <div>
        <div className={cx('title')}>신규카드 등록</div>
        <div className={cx('wrapper')}>
          <div className={cx('item')}>
            <div className={cx('label')}>카드번호</div>
            <input type="text" placeholder="카드번호" value={cardNo} maxLength="18" onChange={this._onChangeCardNo} className={cx('field')} />
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>PIN 번호</div>
            <input type="text" placeholder="PIN 번호" value={cardPinNo} maxLength="7" onChange={this._onChangePinNo} className={cx('field')} />
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>카드명</div>
            <input type="text" placeholder="카드명" value={cardNickName} maxLength="10" onChange={this._onChangeName} className={cx('field')} />
            <div className={cx('button')} onClick={this.doRegistCard}>등록</div>
          </div>
        </div>
      </div>
    );
  }

  _onChangeCardNo = (e) => {
    this.setState({
      cardNo: e.target.value,
    });
  }

  _onChangePinNo = (e) => {
    this.setState({
      cardPinNo: e.target.value,
    });
  }

  _onChangeName = (e) => {
    this.setState({
      cardNickName: e.target.value,
    });
  }

  // 카드등록
  doRegistCard = async () => {
    const { cardNo, cardPinNo, cardNickName } = this.state;
    const registCardResult = await registCard(cardNo, cardPinNo, cardNickName);

    // success
    if ('message' in registCardResult && registCardResult.message === 'SUCCESS') {
      const token = sessionStorage.getItem('access_token');
      this.props.actions.cardOwnerListActions.request({ token });

      alert('카드 등록이 완료되었습니다.');

      this.setState({
        cardNo: '',
        cardPinNo: '',
        cardNickName: '',
      });
    }
    // failure
    else {
      let message;
      switch (registCardResult.code) {
        case 8110:
        message = '카드번호가 유효하지 않습니다.';
        break;

        case 8111:
        message = 'PIN 번호가 유효하지 않습니다.';
        break;

        default:
        message = '잠시 후 다시 시도해주세요.';
        break;
      }

      alert(message);
    }
  }
}

/**
 * Define redux settings
 */
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      cardOwnerListActions: bindActionCreators(cardOwnerListActions, dispatch),
    },
  };
}

export default connect(null, mapDispatchToProps)(Register);