import React from 'react';
import classNames from 'classnames/bind';
import { Container, Row, Col } from 'react-grid-system';

import styles from './index.module.scss';
import TradeList from './TradeList';
import AppDownloadBanner from '../../AppDownloadBanner';

const cx = classNames.bind(styles);

const PointDetail = () => (
  <Container className={cx('container')}>
    <Row>
      <Col xs={12} lg={6} className={cx('column')}>
        <TradeList />
      </Col>
      <Col xs={12} lg={5.5} offset={{ lg: .5 }}>
        <AppDownloadBanner />
      </Col>
    </Row>
  </Container>
);

export default PointDetail;