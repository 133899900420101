import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Container, Row, Col } from 'react-grid-system';

import styles from './index.module.scss';
import HoldList from './HoldList';
import UseList from './UseList';
import AppDownloadBanner from '../../AppDownloadBanner';

const cx = classNames.bind(styles);

export default class CouponDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedListIndex: 0,
    };
  }

  render() {
    const { selectedListIndex } = this.state;

    return (
      <Container className={cx('listWrapper')}>
        <Row>
          <Col xs={12} lg={6} className={cx('column')}>
            <div className={cx('titleWrapper')}>
              <div className={cx(['listTitle', { selected: selectedListIndex === 0 }])} onClick={this.selectList.bind(this, 0)}>쿠폰 보유내역</div>
              <div className={cx('divide')} />
              <div className={cx(['listTitle', { selected: selectedListIndex === 1 }])} onClick={this.selectList.bind(this, 1)}>쿠폰 지난내역</div>
            </div>
            {selectedListIndex === 0 && (
              <HoldList />
            )}
            {selectedListIndex === 1 && (
              <UseList />
            )}
          </Col>
          <Col xs={12} lg={5.5} offset={{ lg: .5 }}>
            <AppDownloadBanner />
          </Col>
        </Row>
      </Container>
    );
  }

  selectList = (selectedListIndex) => {
    this.setState({
      selectedListIndex,
    });
  }
}