import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import moment from 'moment';
import 'moment/locale/ko.js';
import Ionicon from 'react-ionicons';

import styles from './UseList.module.scss';
import { couponUseListActions } from '../../../../../actions';

const cx = classNames.bind(styles);

class UseList extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.useList;
    const { page, useList } = prevState;

    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      const newUseList = useList.concat(data.result.holdList);

      if (useList.length < newUseList.length) {
        return {
          page: page + 1,
          useList: newUseList,
          hasMore: newUseList.length < data.result.totalCount,
        };
      }
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      useList: [],
      hasMore: false,
    };
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');
    
    if (token !== null) {
      this.props.actions.couponUseListActions.request({ token, page: 1 });
    }
  }

  componentWillUnmount() {
    this.props.actions.couponUseListActions.clear();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.useList;
    return !isProcessing && !failure && 'message' in data && data.message === 'SUCCESS';
  }

  render() {
    const { useList, hasMore } = this.state;

    return (
      <div className={cx('list')}>
        {useList.length === 0 && (
          <div className={cx('none')}>쿠폰 발급/사용이력이 없습니다.</div>
        )}
        {useList.map((item, i) => {
          const { couponName, validStartDate, validEndDate, expiredYn, statusCode } = item;
          const isOrange = statusCode === 'USED'; // '사용' 색상 표시

          let status;
          if (statusCode === 'USED') {
            status = <span>사용<br/>완료</span>;
          }
          else if (expiredYn === 'Y') {
            status = <span>기한<br/>n만료</span>;
          }
          else {
            status = 'N/A';
          }

          return (
            <div key={i} className={cx('item')}>
              <div className={cx('left')}>
                <div className={cx('name')}>{couponName}</div>
                <div className={cx('date')}>{moment(validStartDate, 'YYYYMMDDHHmmss').format('YYYY.MM.DD')} ~ {moment(validEndDate, 'YYYYMMDDHHmmss').format('YYYY.MM.DD')}</div>
              </div>
              <div className={cx('right')}>
                <div className={cx(['status', { orange: isOrange }])}>{status}</div>
              </div>
            </div>
          );
        })}
        {hasMore && (
          <div className={cx('moreButton')} onClick={this.loadMore}>
            <div className={cx('loadMoreText')}>더 보기</div>
            <Ionicon icon="ios-arrow-down" fontSize="17px" />
          </div>
        )}
      </div>
    );
  }

  loadMore = () => {
    if (!this.props.useList.isProcessing) {
      const token = sessionStorage.getItem('access_token');

      if (token !== null) {
        this.props.actions.couponUseListActions.request({ token, page: this.state.page + 1 });
      }
    }
  }
}

const mapStateToProps = (state) => {
  const { couponUseList: useList } = state;

  return {
    useList,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      couponUseListActions: bindActionCreators(couponUseListActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UseList);