import React, { Component } from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';

import styles from './index.module.scss';
import { post } from 'api';
import Point from './Point';
import Stamp from './Stamp';
import Coupon from './Coupon';
import Card from './Card';
import Grade from '../../../misc/Grade';

const cx = classNames.bind(styles);

export default class Summary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: null,
    };
  }

  async componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    // 1. 엑세스토큰 있는 경우
    if (token !== null) {
      const result = await post('member/me/sign', { token });
      if (result.isSuccess) {
        this.setState({
          name: result.data.name,
        });
      }
    }
  }

  render() {
    const { name } = this.state;

    return (
      <ScreenClassRender render={(screenClass) => {
        const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

        return (
          <Container className={cx('container')}>
            <Row>
              <Col xs={10} lg={4} offset={{ xs: 1, lg: 7 }}>
                <div className={cx(['statusPanel', { mobile: isMobile }])}>
                  <div className={cx('grade')}><span className={cx('bold')}>{name}</span>님의 회원등급은<br/>현재 <span className={cx(['bold', 'orange'])}><Grade /></span>입니다.</div>
                  <div className={cx('item')}>
                    <div className={cx('label')}>내 포인트</div>
                    <div className={cx('value')}><Point /></div>
                    <div className={cx('unit')}>P</div>
                  </div>
                  <div className={cx('item')}>
                    <div className={cx('label')}>진행 중인 스탬프</div>
                    <div className={cx('value')}><Stamp /></div>
                    <div className={cx('unit')}>개</div>
                  </div>
                  <div className={cx('item')}>
                    <div className={cx('label')}>보유 중인 쿠폰</div>
                    <div className={cx('value')}><Coupon /></div>
                    <div className={cx('unit')}>장</div>
                  </div>
                  <div className={cx('item')}>
                    <div className={cx('label')}>총 카드잔액</div>
                    <div className={cx('value')}><Card /></div>
                    <div className={cx('unit')}>원</div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        );
      }} />
    );
  }
}