import React, { Component } from 'react';
import classNames from 'classnames/bind';
import { Container, Row, Col } from 'react-grid-system';

import styles from './index.module.scss';
import { post } from '../../../api';
import Summary from './Summary';
import PointDetail from './Details/Point';
import StampDetail from './Details/Stamp';
import CouponDetail from './Details/Coupon';
import CardDetail from './Details/Card';
import NotAuth from '../NotAuth';

const cx = classNames.bind(styles);

export default class MyArtisee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null,
      tabIndex: 0,
    };
  }

  async componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    // 1. 엑세스토큰 있는 경우
    if (token !== null) {
      const isActiveTokenResult = await post('auth/check', { token });
      console.debug('isActiveTokenResult', isActiveTokenResult);

      // 1-1. 엑세스토큰 유효한 경우
      if (isActiveTokenResult.active) {
        this.setState({isLoggedIn: true});
      }
      // 1-2. 엑세스토큰 만료된 경우
      else {
        this.setState({isLoggedIn: false});
      }
    }
    // 2. 엑세스토큰 없는 경우
    else {
      this.setState({isLoggedIn: false});
    }
  }

  render() {
    const { isLoggedIn, tabIndex } = this.state;

    // 로그인 전
    if (isLoggedIn !== null && !isLoggedIn) {
      return (
        <NotAuth />
      );
    }
    // 로그인 후
    else if (isLoggedIn !== null && isLoggedIn) {
      return (
        <div className={cx('container')}>
          <Summary />
          
          <Container className={cx('tabBar')}>
            <Row>
              <Col xs={10} offset={{ xs: 1 }}>
                <div className={cx('tabs')}>
                  <div className={cx(['tab', { active: tabIndex === 0 }])} onClick={this.selectTab.bind(this, 0)}>포인트</div>
                  <div className={cx(['tab', { active: tabIndex === 1 }])} onClick={this.selectTab.bind(this, 1)}>카드</div>
                  <div className={cx(['tab', { active: tabIndex === 2 }])} onClick={this.selectTab.bind(this, 2)}>쿠폰</div>
                  <div className={cx(['tab', { active: tabIndex === 3 }])} onClick={this.selectTab.bind(this, 3)}>스탬프</div>
                </div>
              </Col>
            </Row>
          </Container>
  
          {/* 포인트 */}
          {tabIndex === 0 && <PointDetail />}

          {/* 카드 */}
          {tabIndex === 1 && <CardDetail />}

          {/* 쿠폰 */}
          {tabIndex === 2 && <CouponDetail />}

          {/* 스탬프 */}
          {tabIndex === 3 && <StampDetail />}
        </div>
      );
    }

    return null;
  }

  selectTab = (tabIndex) => {
    this.setState({
      tabIndex,
    });
  }
}