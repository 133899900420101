import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';

import styles from './Settings.module.scss';
import { changeCardNickName, changeCardStatus } from 'api';
import { cardOwnerListActions } from '../../../../../actions';

const cx = classNames.bind(styles);

class Settings extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.cardNo !== nextProps.card.cardNo) {
      return {
        cardNo: nextProps.card.cardNo,
        nickNm: nextProps.card.nickNm,
        isUse: nextProps.card.cardStatusCode === 'USE',
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      cardNo: props.card.cardNo,
      nickNm: props.card.nickNm,
      isUse: props.card.cardStatusCode === 'USE',
    };
  }

  render() {
    const { nickNm, isUse } = this.state;

    return (
      <div>
        <div className={cx('title')}>카드정보 변경</div>
        <div className={cx('wrapper')}>
          <div className={cx('item')}>
            <div className={cx('label')}>카드명</div>
            <input type="text" placeholder="카드명" value={nickNm} maxLength="10" onChange={this._onChangeName} className={cx('field')} />
            <div className={cx('button')} onClick={this.doChangeName}>변경</div>
          </div>
          <div className={cx('item')}>
            <div className={cx('label')}>카드상태</div>
            <div className={cx('radioWrapper')}>
              {this.getRadioItem(false, isUse, '사용', this.changeStatus.bind(this, 'use'))}
              {this.getRadioItem(false, !isUse, '중지', this.changeStatus.bind(this, 'pause'))}
            </div>
            <div className={cx('button')} onClick={this.doChangeStatus}>변경</div>
          </div>
        </div>
      </div>
    );
  }

  getRadioItem = (disabled, isOn, label, onPress) => {
    const icon = isOn ? 'md-radio-button-on' : 'md-radio-button-off';
    const color = disabled ? '#ccc' : isOn ? 'rgb(247, 144, 30)' : 'black';

    return (
      <div className={cx('radioItem')} onClick={disabled ? null : onPress}>
        <Ionicon icon={icon} fontSize="21px" color={color} />
        <div className={cx('radioLabel')} style={{ color }}>{label}</div>
      </div>
    );
  }

  changeStatus = (status) => {
    this.setState({
      isUse: status === 'use',
    });
  }

  _onChangeName = (e) => {
    this.setState({
      nickNm: e.target.value,
    });
  }

  // 카드명변경
  doChangeName = async () => {
    const { cardNo, nickNm } = this.state;
    const changeCardNickNameResult = await changeCardNickName(cardNo, nickNm);

    // success
    if ('message' in changeCardNickNameResult && changeCardNickNameResult.message === 'SUCCESS') {
      this.props.actions.cardOwnerListActions.changeName({ cardNo, newNickName: nickNm });

      alert('카드명 변경이 완료되었습니다.');
    }
    // failure
    else {
      alert('카드명 변경을 실패하였습니다.');
    }
  }

  // 카드상태변경
  doChangeStatus = async () => {
    const { cardNo } = this.state;
    const toBeStatus = this.state.isUse ? 'USE' : 'PAUSE';
    const pauseReason = 'ETC'; // 중지사유 '기타' (LOSS:분실)

    const body = {
      cardNo,
      toBeStatus,
    };

    if (!this.state.isUse) {
      body.pauseReason = pauseReason;
    }

    const changeCardStatusResult = await changeCardStatus(body);

    // success
    if ('message' in changeCardStatusResult && changeCardStatusResult.message === 'SUCCESS') {
      this.props.actions.cardOwnerListActions.changeStatus({ cardNo, toBeStatus, pauseReason });

      alert('카드상태 변경이 완료되었습니다.');
    }
    // failure
    else {
      alert('잠시 후 다시 시도해주세요.');
    }
  }
}

/**
 * Define redux settings
 */
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      cardOwnerListActions: bindActionCreators(cardOwnerListActions, dispatch),
    },
  };
}

export default connect(null, mapDispatchToProps)(Settings);