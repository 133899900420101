import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import moment from 'moment';
import 'moment/locale/ko.js';
import commaNumber from 'comma-number';
import Ionicon from 'react-ionicons';

import styles from './TradeList.module.scss';
import { cardTradeListActions } from '../../../../../actions';

const cx = classNames.bind(styles);

class TradeList extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    // When card is changed in props
    if (prevState.cardNo !== nextProps.cardNo) {
      return {
        cardNo: nextProps.cardNo,
        page: 0,
        tradeList: [],
        hasMore: false,
      };
    }

    const { isProcessing, failure, data } = nextProps.tradeList;
    const { page, tradeList } = prevState;
    
    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      const newTradeList = tradeList.concat(data.result.tradeList);

      if (tradeList.length < newTradeList.length) {
        return {
          page: page + 1,
          tradeList: newTradeList,
          hasMore: newTradeList.length < data.result.totalCount,
        };
      }
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      cardNo: props.cardNo,
      page: 0,
      tradeList: [],
      hasMore: false,
    };
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    if (token !== null) {
      this.props.actions.cardTradeListActions.request({ token, cardNo: this.state.cardNo, page: 1 });
    }
  }

  componentWillUnmount() {
    this.props.actions.cardTradeListActions.clear();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.tradeList;
    return !isProcessing && !failure && 'message' in data && data.message === 'SUCCESS';
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.cardNo !== this.state.cardNo) {
      const token = sessionStorage.getItem('access_token');
      this.props.actions.cardTradeListActions.request({ token, cardNo: this.state.cardNo, page: 1 });
    }
  }

  render() {
    const { tradeList, hasMore } = this.state;

    return (
      <div>
        <div className={cx('listTitle')}>카드 거래이력</div>
        <div className={cx('list')}>
          {tradeList.length === 0 && (
            <div className={cx('none')}>카드 거래이력이 없습니다.</div>
          )}
          {tradeList.map((item, i) => {
            const { pointTradeTypeName, detailTradeReasonName, tradeDate, tradePoint, /* snapshotTotalRestPoint */ } = item;
            const isPlus = pointTradeTypeName === '적립' || pointTradeTypeName === '적립 사용 취소' || pointTradeTypeName === '충전';

            return (
              <div key={i} className={cx('item')}>
                <div className={cx('left')}>
                  <div className={cx('reason')}>{detailTradeReasonName}</div>
                  <div className={cx('date')}>{moment(tradeDate, 'YYYYMMDDHHmmss').format('YYYY.MM.DD | hh:mm:ss')}</div>
                </div>
                <div className={cx('right')}>
                  <div className={cx('type')}>{this.getTradeType(pointTradeTypeName)}</div>
                  <div className={cx(['point', { orange: isPlus }])}>{isPlus ? '+' : '-'} {commaNumber(tradePoint)}P</div>
                </div>
              </div>
            );
          })}
          {hasMore && (
            <div className={cx('moreButton')} onClick={this.loadMore}>
              <div className={cx('loadMoreText')}>더 보기</div>
              <Ionicon icon="ios-arrow-down" fontSize="17px" />
            </div>
          )}
        </div>
      </div>
    );
  }

  loadMore = () => {
    if (!this.props.tradeList.isProcessing) {
      const token = sessionStorage.getItem('access_token');

      if (token !== null) {
        this.props.actions.cardTradeListActions.request({ token, cardNo: this.state.cardNo, page: this.state.page + 1 });
      }
    }
  }

  getTradeType = (type) => {
    // '적립 사용', '충전 사용' 구분 없이 모두 '사용'으로 처리
    if (type.endsWith('사용')) {
      return '사용';
    }
  
    return type;
  }
}

/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { cardTradeList: tradeList } = state;

  return {
    tradeList,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      cardTradeListActions: bindActionCreators(cardTradeListActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TradeList);