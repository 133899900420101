import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import commaNumber from 'comma-number';

import { cardOwnerListActions } from '../../../../actions';

class Card extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.cardOwnerList;

    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      const cardAmount = data.result.reduce((total, card) => total + card.restPayPoint, 0);

      if (prevState.cardAmount !== cardAmount) {
        return {
          cardAmount,
        };
      }
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      cardAmount: 0,
    };
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');
    if (token !== null) {
      this.props.actions.cardOwnerListActions.request({ token });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.cardOwnerList;
    return !isProcessing && !failure && 'message' in data && data.message === 'SUCCESS';
  }

  render() {
    const { cardAmount } = this.state;

    return (
      <span>{commaNumber(cardAmount)}</span>
    );
  }
}

/**
 * Define redux settings
 */
const mapStateToProps = (state) => {
  const { cardOwnerList } = state;

  return {
    cardOwnerList,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      cardOwnerListActions: bindActionCreators(cardOwnerListActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Card);