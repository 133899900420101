import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames/bind';
import moment from 'moment';
import 'moment/locale/ko.js';
import Ionicon from 'react-ionicons';

import styles from './TradeList.module.scss';
import { stampTradeListActions } from '../../../../../actions';

const cx = classNames.bind(styles);

class TradeList extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.tradeList;
    const { page, tradeList } = prevState;

    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      const newTradeList = tradeList.concat(data.result.tradeList);

      if (tradeList.length < newTradeList.length) {
        return {
          page: page + 1,
          tradeList: newTradeList,
          hasMore: newTradeList.length < data.result.totalCount,
        };
      }
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      tradeList: [],
      hasMore: false,
    };
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    if (token !== null) {
      this.props.actions.stampTradeListActions.request({ token, page: 1 });
    }
  }

  componentWillUnmount() {
    this.props.actions.stampTradeListActions.clear();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.tradeList;
    return !isProcessing && !failure && 'message' in data && data.message === 'SUCCESS';
  }

  render() {
    const { tradeList, hasMore } = this.state;

    return (
      <div className={cx('list')}>
        {tradeList.length === 0 && (
          <div className={cx('none')}>스탬프 적립/사용이력이 없습니다.</div>
        )}
        {tradeList.map((item, i) => {
          const { tradeDate, stampName, saveType, tradeStampCount, /* accumulatedStampCount */ } = item;
          const isPlus = saveType === 'SAVE';

          return (
            <div key={i} className={cx('item')}>
              <div className={cx('left')}>
                <div className={cx('name')}>{stampName}</div>
                <div className={cx('date')}>{moment(tradeDate, 'YYYYMMDDHHmmss').format('YYYY.MM.DD | hh:mm:ss')}</div>
              </div>
              <div className={cx('right')}>
                <div className={cx('type')}>{this.getSaveType(saveType)}</div>
                <div className={cx(['count', { orange: isPlus }])}>{isPlus ? '+' : '-'} {tradeStampCount}</div>
              </div>
            </div>
          );
        })}
        {hasMore && (
          <div className={cx('moreButton')} onClick={this.loadMore}>
            <div className={cx('loadMoreText')}>더 보기</div>
            <Ionicon icon="ios-arrow-down" fontSize="17px" />
          </div>
        )}
      </div>
    );
  }

  loadMore = () => {
    if (!this.props.tradeList.isProcessing) {
      const token = sessionStorage.getItem('access_token');

      if (token !== null) {
        this.props.actions.stampTradeListActions.request({ token, page: this.state.page + 1 });
      }
    }
  }

  getSaveType = (saveType) => { 
    let saveTypeText; 
    switch (saveType) { 
      case 'SAVE': 
      saveTypeText = '적립'; 
      break; 
 
      case 'SAVE_CANCEL': 
      saveTypeText = '적립취소'; 
      break; 
 
      default: 
      break; 
    } 
 
    return saveTypeText; 
  }
}

const mapStateToProps = (state) => {
  const { stampTradeList: tradeList } = state;

  return {
    tradeList,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      stampTradeListActions: bindActionCreators(stampTradeListActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TradeList);