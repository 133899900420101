export default (text) => {
  try {
    if (text.length > 16) {
      return `${text.substring(0, 4)} - ${text.substring(4, 8)} - ${text.substring(8, 12)} - ${text.substring(12, 16)} - ${text.substring(16)}`;
    } else {
      return `${text.substring(0, 4)} - ${text.substring(4, 8)} - ${text.substring(8, 12)} - ${text.substring(12)}`;
    }
  }
  catch (e) {
    return text;
  }
}