import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const AppDownloadBanner = () => (
  <div className={cx('container')}>
    <div className={cx('title')}>클럽아티제 모바일앱 리뉴얼</div>
    <div className={cx('paragraph')}>
      새롭게 바뀐 클럽아티제 모바일앱으로<br />
      아티제가 드리는 다양한 혜택을 누리세요.
    </div>
    <div className={cx('buttons')}>
      <a href="https://itunes.apple.com/us/app/클럽아티제-아티제-멤버십/id1437734675?l=ko&ls=1&mt=8" target="_blank" rel="noopener noreferrer" className={cx('button')}>
        <img src={require('assets/images/club/download-btn-appstore.png')} alt="클럽아티제" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.clubartisee" target="_blank" rel="noopener noreferrer" className={cx('button')}>
        <img src={require('assets/images/club/download-btn-googleplay.png')} alt="클럽아티제" />
      </a>
    </div>
    <img src={require('assets/images/club/download-phone.png')} alt="클럽아티제" width="100%" className={cx('phoneWrapper')} />
  </div>
);

export default AppDownloadBanner;