import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import commaNumber from 'comma-number';

import { couponHoldListActions } from '../../../../actions';

class Coupon extends Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isProcessing, failure, data } = nextProps.couponHoldList;

    if (!isProcessing && !failure && 'message' in data && data.message === 'SUCCESS') {
      const count = data.result.totalCount;

      if (prevState.count !== count) {
        return {
          count,
        };
      }
    }
    
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  componentDidMount() {
    const token = sessionStorage.getItem('access_token');
    if (token !== null) {
      this.props.actions.couponHoldListActions.request({ token, page: 1 });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isProcessing, failure, data } = nextProps.couponHoldList;
    return !isProcessing && !failure && 'message' in data && data.message === 'SUCCESS';
  }

  render() {
    const { count } = this.state;
    
    return (
      <span>{commaNumber(count)}</span>
    );
  }
}

const mapStateToProps = (state) => {
  const { couponHoldList } = state;

  return {
    couponHoldList,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      couponHoldListActions: bindActionCreators(couponHoldListActions, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);